import React, { Component } from 'react';
import { ReactComponent as Logo } from './../assets/img/icon.svg';

class Loading extends Component{
  constructor(){
    super();
    
    this.state = {}
  }

  componentDidMount(){}

  componentDidUpdate(prevProps, prevState){}

  componentWillUnmount(){}

  render(){
    return(
      <div className={`loading ${this.props.section ? 'section' : ''}`}>
        <Logo />
        {this.props.text ? this.props.text + '...' : 'Loading...'}
      </div>
    );
  }
}

export default Loading;