import React, { Component, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import makeCancellablePromise from 'make-cancellable-promise';
import { doc, onSnapshot, collection, addDoc } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
// import { Helmet } from 'react-helmet-async';

import { auth, db } from './../helper/firebase';
import Loading from './../components/Loading.js';
import withRouter from './../helper/withrouter.js';

const 
      // Home = React.lazy(() => {
      //   return new Promise(resolve => {
      //     setTimeout(() => resolve(import('./Home/Home')), 3000000);
      //   });
      // }),
      Login = React.lazy(() => import('./Login/Login')),
      Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      signIn: '',
      dataUser: {
        uid: '',
        name: '',
        email: '',
        phone: ''
      },
      ip: ''
    }
    
    this.cancellableIp = '';
    this.unsubscribe = '';
    this.cancellableLogout = '';
    this.cancellableLog = '';

    this.logOut = this.logOut.bind(this);
    this.addLog = this.addLog.bind(this);
  }

  componentDidMount(){
    this.cancellableIp = makeCancellablePromise(
      fetch('https://geolocation-db.com/json/')
    );

    this.cancellableIp.promise.then((res) => res.json()).then((data) => {
      this.setState({
        ip: data
      });
    });
    
    onAuthStateChanged(auth, (user) => {
      if(user){
        this.unsubscribe = onSnapshot(doc(db, 'user', user.uid), (doc) => {
          if(doc.exists()){
            const userData = doc.data();
            
            if(userData.userStatus && userData.userActive){
              this.setState({
                signIn: true,
                dataUser: {
                  uid: user.uid,
                  name: userData.userName,
                  email: userData.userEmail,
                  phone: userData.userPhone
                }
              });
            }else{
              this.logOut(user.uid);
            }
          }else{
            this.logOut(user.uid);
          }
        }, (error) => {
          this.logOut(user.uid);

          console.error(error);
          toast.error(() => (<>{error.code}<span>{error.message}</span></>));
        });
      }else{
        this.setState({
          signIn: false,
          dataUser: {
            uid: '',
            name: '',
            email: '',
            phone: ''
          }
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.dataUser !== prevState.dataUser){
      if(typeof prevState.dataUser !== 'undefined'){
        if(this.state.dataUser.email !== prevState.dataUser.email){
          if(this.state.dataUser.email){
            toast.success(() => (<>Sign In Successfully<span>Hi <strong>{this.state.dataUser.email}</strong>!</span></>));
          }else{
            toast.success(() => (<>Sign Out Successfully<span>Bye!</span></>));
          }
        }
      }
    }
  }

  componentWillUnmount(){
    if(this.cancellableIp){
      this.cancellableIp.cancel();
    }

    if(this.unsubscribe){
      this.unsubscribe();
    }

    if(this.cancellableLogout){
      this.cancellableLogout.cancel();
    }

    if(this.cancellableLog){
      this.cancellableLog.cancel();
    }
  }

  logOut(uid){
    this.cancellableLogout = makeCancellablePromise(signOut(auth));

    this.cancellableLogout.promise.then(() => {
      this.addLog('auth', 'Sign Out', 'Sign out user/' + uid);

      if(this.unsubscribe){
        this.unsubscribe();
      }
    }).catch((error) => {
      console.error(error);
      toast.error(() => (<>{error.code}<span>{error.message}</span></>));
    });
  }

  addLog(act, name, desc){
    this.setState({ formLoading: true });

    this.cancellableLog = makeCancellablePromise(
      addDoc(collection(db, 'log'), {
        logAct: act,
        logName: name,
        logDesc: desc,
        logAgent: window.navigator.userAgent,
        logIp: this.state.ip,
        logTime: new Date()
      })
    );

    this.cancellableLog.promise.then(() => {
      // toast.success(() => (<>Adding {this.props.nav.name} Successfully!<span>{this.props.nav.name} <strong>{this.state.number}</strong> person discount already <strong>added</strong>.</span></>));
    }).catch((error) => {
      console.error(error);
      // toast.error(() => (<>{error.code}<span>{error.message}</span></>));
    });
  }

  render(){
    return(
      <>
        {this.state.signIn === '' ? (
          <Loading text="Authenticating" />
        ) : (
          <Suspense fallback={
            <Loading />
          }>
            <Routes>
              <Route path="/login" element={
                this.state.signIn ? (<Navigate to="/admin" />) : (<Login router={this.props.router} addLog={this.addLog} />)
              } />
              <Route path="/admin/*" element={
                this.state.signIn ? (<Dashboard router={this.props.router} dataUser={this.state.dataUser} logOut={this.logOut} addLog={this.addLog} />) : (<Navigate to="/login" />)
              } />
              <Route path="*" element={
                this.state.signIn ? (<Navigate to="/admin" />) : (<Navigate to="/login" />)
              } />
            </Routes>
          </Suspense>
        )}
      </>
    );
  }
}

export default withRouter(App);