// import { useLocation, useNavigate } from 'react-router-dom';

// export default function withRouter( Child ) {
//     return ( props ) => {
//         const location = useLocation();
//         const navigate = useNavigate();
//         return <Child { ...props } navigate={ navigate } location={ location } />;
//     }
// }

import {
    useLocation,
    useNavigate,
    useParams,
    // useSearchParams
  } from "react-router-dom";
  
  export default function withRouter(Component) {
    function ComponentWithRouterProp(props) {
      const location = useLocation(),
            navigate = useNavigate(),
            params = useParams();
      // let search = useSearchParams();
      
      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
        />
      );
    }
  
    return ComponentWithRouterProp;
  }