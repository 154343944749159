import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseApp = initializeApp({
    apiKey: "AIzaSyA15nD6I2IpFxSGgsxwQJLAfqu-m4KDByE",
    authDomain: "la-galigo-liveboard.firebaseapp.com",
    projectId: "la-galigo-liveboard",
    storageBucket: "la-galigo-liveboard.appspot.com",
    messagingSenderId: "1022196225788",
    appId: "1:1022196225788:web:1c1d437b8f1906478da979",
    measurementId: "G-PXX3RW3YML"
});

getAnalytics(firebaseApp);

export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);

export default firebaseApp;